export function show ( element, style ) {
    if (style) {
        element.style.display = style
    } else {
        element.style.display = 'block'
    }
}

export function hide ( element ) {
    element.style.display = 'none'
}

export function addClass ( classname, element ) {
    var cn = element.className
    // test for existance
    if ( cn.indexOf( classname ) != -1 ) {
        return
    }
    // add a space if the element already has class
    if ( cn != '' ) {
        classname = ' ' + classname
    }
    element.className = cn + classname
}

export function removeClass ( classname, element ) {
    var cn = element.className
    var rxp = new RegExp( classname, "g" )
    cn = cn.replace( rxp, '' )
    element.className = cn
}

export function hasClass ( classname, element ) {
    var cn = element.className
    var rxp = new RegExp( classname, "g" )
    return rxp.test( cn )
}

export function disableScroll () {
    if (window.addEventListener) { // older FF
        window.addEventListener('DOMMouseScroll', preventDefault, false)
    }
    window.onwheel = preventDefault // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE
    window.ontouchmove  = preventDefault // mobile
    document.onkeydown  = preventDefaultForScrollKeys
}

export function enableScroll () {
    if (window.removeEventListener) {
        window.removeEventListener('DOMMouseScroll', preventDefault, false)
    }
    window.onmousewheel = document.onmousewheel = null
    window.onwheel = null
    window.ontouchmove = null
    document.onkeydown = null
}

/**
 * {JSDoc}
 *
 * The splice() method changes the content of a string by removing a range of
 * characters and/or adding new characters.
 *
 * @this {String}
 * @param {number} start Index at which to start changing the string.
 * @param {number} delCount An integer indicating the number of old chars to remove.
 * @param {string} newSubStr The String that is spliced in.
 * @return {string} A new string with the spliced substring.
 */
export function splice (string, start, delCount, newSubStr) {
    return string.slice(0, start) + newSubStr + string.slice(start + Math.abs(delCount))
}
