import throttle from 'lodash/throttle'
import {show,hide,addClass,removeClass,hasClass} from '@js/modules/helper'
import debug from '@js/modules/debug'

const SELECTORS = {
    fullElement: '.full',
    tabsElement: '.shorttabs',
    togglesElement: '.shorttoggle',
}

// SHORTCODE JS

// FULL
export class PositionFull {
    constructor () {
        this.fullElement = document.querySelectorAll(SELECTORS.fullElement)
        if (this.fullElement.length) {
            // window.addEventListener(
            //     'load',//'DOMContentLoaded',
            //     () => this.setPosition()
            // )
            this.setPosition()

            window.addEventListener(
                'resize',
                throttle(() => this.setPosition(), 60)
            )
        }
    }

    setPosition () {
        for (let i = 0; i < this.fullElement.length; i += 1) {
            const el = this.fullElement[i]
            const elInner = el.querySelector('.inner-full')
            if (elInner) {
                const h = elInner.offsetHeight
                el.style.paddingBottom = h + 'px'
            }
        }
    }
}

// TABS
export class Tabs {
    constructor () {
        this.tabsElement = document.querySelectorAll(SELECTORS.tabsElement)
        if (this.tabsElement.length) {
            // window.addEventListener(
            //     'load',//'DOMContentLoaded',
            //     () => this.init()
            // )
            this.init()
        }
    }

    init () {
        for (let i = 0; i < this.tabsElement.length; i += 1) {
            const el = this.tabsElement[i]
            const tabsContent = el.querySelectorAll('.shorttabs__content')
            const tabsHeader = el.querySelectorAll('.shorttabs__header')

            this.hideTabsContent(tabsContent)

            const clickEvent = (e) => {
                const tabheader = e.target
                const target = el.querySelector('.shorttabs__content[data-id=\'' + tabheader.dataset.id + '\']')

                if (target) {
                    this.hideTabsContent(tabsContent)

                    const tabheaderActive = el.querySelector('.shorttabs__header.active')
                    if (tabheaderActive) {
                        removeClass('active', tabheaderActive)
                    }

                    addClass('active', tabheader)
                    show(target)
                }
            }

            for (let j = 0; j < tabsHeader.length; j += 1) {
                tabsHeader[j].addEventListener(
                    'click',
                    clickEvent
                )

                if (j == 0) {
                    tabsHeader[j].click()
                }
            }
        }
    }

    hideTabsContent(elements) {
        for (let i = 0; i < elements.length; i += 1) {
            hide(elements[i])
        }
    }
}

// TOGGLES
export class Toggles {
    constructor () {
        this.togglesElement = document.querySelectorAll(SELECTORS.togglesElement)
        if (this.togglesElement.length) {
            // window.addEventListener(
            //     'load',//'DOMContentLoaded',
            //     () => this.init()
            // )
            this.init()
        }
    }

    init () {
        for (let i = 0; i < this.togglesElement.length; i += 1) {
            const el = this.togglesElement[i]
            const toggleHeader = el.querySelector('.shorttoggle__header')

            const clickEvent = (e) => {
                const el = e.target
                const toggle = el.parentNode
                // const togglecontent = toggle.querySelector('.togglecontent')

                if (hasClass('closed', toggle)) {
                    removeClass('closed', toggle)
                } else {
                    addClass('closed', toggle)
                }
            }

            toggleHeader.addEventListener(
                'click',
                clickEvent
            )
        }
    }
}
