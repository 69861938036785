const settings = {
    brand_primary  : '#c49f7b',
    brand_secondary: '#333',
    gray_lighter   : '#eceeef',
    gray_lightest  : '#f7f7f9',
    white          : '#fff',
    ease           : [.19,1,.22,1],
    ease_in_out    : [.77,0,.175,1],
    transition     : 400,
    transition_fast: 250,
    transition_slow: 800,
    transition__inS     : .400,
    transition_fast__inS: .250,
    transition_slow__inS: .800,
}

export default settings