import variables from '@js/variables'
import debug from '@js/modules/debug'

const SELECTORS = {
    cookieNotice: '#cookie-notice',
    cnContainer: '.cookie-notice-container',
    cnAccept: '#cn-accept-cookie'
}


export default class Cookie {
    constructor (App) {
        this.App = App
        this.cookieNotice = document.querySelector(SELECTORS.cookieNotice)
        if (this.cookieNotice) {
            const t = this

            // if ( Util.hasClass(RM.Dom.body, 'cookies-accepted') ) {
            //     window.addEventListener('load', (e) => {
            //       this.cookieNotice.remove();
            //     });
            //     return;
            // }

            this.cnContainer = this.cookieNotice.querySelector(SELECTORS.cnContainer)
            this.cnAccept    = this.cookieNotice.querySelector(SELECTORS.cnAccept)

            import('animejs').then(function (obj) {
                t.anime = obj.default
                t.init()
            })
        }
    }

    init () {
        const t = this
        this.cnAccept.addEventListener('click', (e) => {
            var cookieAnime = this.anime.timeline({
                duration: variables.transition,
                easing: variables.ease
            })
            cookieAnime
                .add({
                    targets: this.cnContainer,
                    opacity: 0,
                })
                .add({
                    targets: this.cookieNotice,
                    scaleY: 0,
                    offset: '-=' + (variables.transition / 2),
                    begin: function(anim) {
                        t.cookieNotice.style.transformOrigin = 'center top'
                        t.cookieNotice.style.transitionDelay = '0s'
                    }
                })

            cookieAnime.finished.then(() => {
                this.cookieNotice.remove()
            })
        })
    }
}
