import '@js/modules/skip-link-focus-fix.js'
import '@js/modules/polyfill'
import Promise from 'es6-promise-promise'
import modernizr from '@js/vendor/modernizr-3.6.0.min.js'
import throttle from 'lodash/throttle'
// import Debucsser from 'debucsser'
import variables from '@js/variables'
import debug from '@js/modules/debug'
import {hasClass} from '@js/modules/helper'
import Cookie from '@js/modules/cookie'
import Navigation from '@js/modules/navigation'
import PageTransition from '@js/modules/pageTransition'
import * as base from '@js/modules/base'


const SELECTORS = {
  loaderProgress: '#pageloader .pageloader__progress',

  linkWords: '.link-words',
  linkLetters: '.link-letters',

  blockReveal: '.blockReveal',
  scrollReveal: '.scrollReveal',
  sliceReveal: '.sliceReveal',

  parallax: '.item-parallax:not(.no-instance)'
}
const CLASSES = {
  loading: 'loading',
  loaded: 'loaded',
  animation: 'fade-in'
}


// Main App Object
const App = {

  loadTotal: 0,
  loadProgress: 0,
  modulesToInit: {},

  env: {
    isIE: navigator.userAgent.indexOf('MSIE ') > 0 || navigator.userAgent.match(/Trident.*rv\:11\./) !== null,
    isMobile: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null,
    intersectionObserverSupport: "IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype,
    mutationObserverSupport: "MutationObserver" in window,
    idleCallbackSupport: "requestIdleCallback" in window
  },

  init() {
    const t       = this
    document.documentElement.classList.add( this.env.isIE ? 'isIE' : 'isNotIE' )
    document.documentElement.classList.add( this.env.isMobile ? 'isMobile' : 'isNotMobile' )

    this.pageloaderProgress          = document.querySelector(SELECTORS.loaderProgress)
    // this.modulesToInit['PageLoader'] = 1
    this.modulesToInit['Gallery']    = 1
    this.modulesToInit['Lightbox']   = 1
    this.body                        = document.body
    // document.documentElement.classList.remove('no-js');

    // Debucsser
    // this.initDebucsser()

    this.Cookie          = new Cookie(this)
    this.Navigation      = new Navigation(this)
    this.PageTransition  = new PageTransition(this)
    this.registerServiceWorker()
    this.initScrollTop()
    this.initQuicklink()

    // All pages > init scripts if there is at least one
    if ( this.body.querySelector(SELECTORS.linkWords) || this.body.querySelector(SELECTORS.linkLetters) ) {
      import('@js/modules/link').then(function (module) {
        const Link = module.default
        t.Link = new Link(t)
      })
    }
    if ( this.body.querySelector(SELECTORS.parallax) ) {
      this.modulesToInit['Parallax'] = 1
      import('@js/modules/parallax').then(function (module) {
        const Parallax = module.default
        t.Parallax = new Parallax(t)
        t.Parallax.initialize()
      })
    }
    if ( this.body.querySelector(SELECTORS.sliceReveal) ) {
      this.modulesToInit['SliceReveal'] = 1
      import('@js/modules/sliceReveal').then(function (module) {
        const SliceRevealer = module.default
        t.SliceReveal = new SliceRevealer(t)
        t.SliceReveal.initialize()
      })
    }
    if ( this.body.querySelector(SELECTORS.blockReveal) ) {
      this.modulesToInit['BlockReveal'] = 1
      import('@js/modules/blockReveal').then(function (module) {
        const BlockRevealer = module.default
        t.BlockReveal = new BlockRevealer(t)
        t.BlockReveal.initialize()
      })
    }
    if ( this.body.querySelector(SELECTORS.scrollReveal) ) {
      this.modulesToInit['ScrollReveal'] = 1
      import('@js/modules/scrollReveal').then(function (module) {
        const ScrollRevealer = module.default
        t.ScrollReveal = new ScrollRevealer(t)
        t.ScrollReveal.initialize()
      })
    }

    // All pages
    // import('@js/modules/pageLoader').then(function (module) {
    //   const PageLoader = module.default
    //   t.PageLoader = new PageLoader(t)
    // })
    import('@js/modules/gallery').then(function (module) {
      const Gallery = module.default
      t.Gallery = new Gallery(t)
      t.Gallery.initialize()
    })
    import('@js/modules/lightbox').then(function (module) {
      const Lightbox = module.default
      t.Lightbox = new Lightbox(t)
      t.Lightbox.initialize()
    })

    this.loadTotal += Object.keys(this.modulesToInit).length
  },

  getScrollY () {
    return window.pageYOffset || document.documentElement.scrollTop;
  },

  // Init Scroll Top button
  initScrollTop () {
    const t = this
    this.scrollTop = document.querySelector('#scrollTop')
    const window_h = window.innerHeight

    const onScrollTopClick = e => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      e.preventDefault()
    }
    this.scrollTop.addEventListener('click', onScrollTopClick)

    window.addEventListener(
      'scroll',
      throttle((e) => {
        var sy = t.getScrollY();

        // if ( sy >= (window_h / 10) ) {
        //   t.body.classList.add('hasScroll')
        // } else {
        //   t.body.classList.remove('hasScroll')
        // }

        if ( sy >= (window_h / 2) ) {
          this.scrollTop.classList.remove('invisible')
        } else {
          this.scrollTop.classList.add('invisible')
        }
      }, 60)
    )
  },

  // Init Quicklink
  // => prefetch in-viewport links during idle time
  // https://github.com/GoogleChromeLabs/quicklink
  initQuicklink () {
    if (! this.env.intersectionObserverSupport ) {
      return
    }
    window.addEventListener('load', () => {
      import('quicklink').then(function (module) {
        const quicklink = module.default

        quicklink({
          // origins: [
          //   site_url.replace(/(^\w+:|^)\/\//, '')
          // ], // default to : [location.hostname]
          ignores: [
            /\/api\/?/,
            /\/wp-admin\/?/,
            /\/wp-login\/?/,
            uri => uri.includes('.zip'),
            uri => uri.includes('#'),
            (uri, elem) => elem.hasAttribute('noprefetch')
          ]
        })
      })
    })
  },

  // lazyLoading() {
  //     this.LazyLoading = new Blazy({
  //         selector: '.lazyload',
  //         successClass: 'loaded'
  //     })
  // },

  displayLoadProgress () {
    let progress = 0,
        progress_base = 0
    if ( this.loadTotal > 0 ) {
      progress_base = this.loadProgress / this.loadTotal
      progress = progress_base * 100
    }

    this.pageloaderProgress.style.transform = 'scaleX(' + progress_base + ')'
  },

  checkAppLoaded () {
    const t = this
    let isLoaded = true

    // if (isLoaded && this.modulesToInit['PageLoader']) {
    //   isLoaded = typeof this.PageLoader != 'undefined' && this.PageLoader.isLoaded
    //   debug('is loaded PageLoaded ' + isLoaded)
    // }
    if (isLoaded && this.modulesToInit['Gallery']) {
      isLoaded = typeof this.Gallery != 'undefined' && this.Gallery.isLoaded
    }
    if (isLoaded && this.modulesToInit['Lightbox']) {
      isLoaded = typeof this.Lightbox != 'undefined' && this.Lightbox.isLoaded
    }
    if (isLoaded && this.modulesToInit['Parallax']) {
      isLoaded = typeof this.Parallax != 'undefined' && this.Parallax.isLoaded
    }
    if (isLoaded && this.modulesToInit['ScrollReveal']) {
      isLoaded = typeof this.ScrollReveal != 'undefined' && this.ScrollReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['BlockReveal']) {
      isLoaded = typeof this.BlockReveal != 'undefined' && this.BlockReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['SliceReveal']) {
      isLoaded = typeof this.SliceReveal != 'undefined' && this.SliceReveal.isLoaded
    }

    this.displayLoadProgress()

    if ( isLoaded ) {
      this.body.classList.remove(CLASSES.loading)
      // document.getElementById('main').classList.add(CLASSES.animation)

      setTimeout(() => {
        t.body.classList.add(CLASSES.loaded)
        // document.querySelector('#pageloader').remove()

        if ( this.modulesToInit['ScrollReveal'] ) t.ScrollReveal.init()
        if ( this.modulesToInit['BlockReveal'] ) t.BlockReveal.init()
        if ( this.modulesToInit['SliceReveal'] ) t.SliceReveal.init()
        if ( this.modulesToInit['Parallax'] ) t.Parallax.init()

        new base.PositionFull()
        new base.Tabs()
        new base.Toggles()

        t.copyright()
      // }, variables.transition + variables.transition_fast)
      }, variables.transition)
    }
  },

  // check for SW support and register
  registerServiceWorker () {
    if ('serviceWorker' in navigator) {
      const t = this
      // const scope = '/';
      const scope = '/its-transport/'

      // Delay registration until after the page has loaded, to ensure that our
      // precaching requests don't degrade the first visit experience.
      // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
      /*window.addEventListener('load', function () {
        // Your service-worker.js *must* be located at the top-level directory relative to your site.
        // It won't be able to control pages unless it's located at the same level or higher than them.
        // See https://github.com/slightlyoff/ServiceWorker/issues/468
        navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
          debug('Service Worker registered successfully.')

          // updatefound is fired if service-worker.js changes.
          reg.onupdatefound = function() {
            // The updatefound event implies that reg.installing is set; see
            // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
            var installingWorker = reg.installing

            installingWorker.onstatechange = function() {
              switch (installingWorker.state) {
                case 'installed':
                  if (navigator.serviceWorker.controller) {
                      // At this point, the old content will have been purged and the fresh content will
                      // have been added to the cache.
                      // It's the perfect time to display a "New content is available; please refresh."
                      // message in the page's interface.
                      debug('New or updated content is available.')
                  } else {
                      // At this point, everything has been precached.
                      // It's the perfect time to display a "Content is cached for offline use." message.
                      debug('Content is now available offline!')
                  }
                  break;

                case 'redundant':
                  console.error('The installing service worker became redundant.')
                  break;
              }
            };
          };
        }).catch(function(e) {
            console.error('Error during service worker registration:', e)
        })

        // Background Sync
        // navigator.serviceWorker.ready.then((swRegistration) => {
        //   return swRegistration.sync.register('event1')
        // })
      })*/
    }
  },

  // initDebucsser () {
  //   // pass all the custom properties you want
  //   const config = {
  //     color: '#ffc308', // color of the outline
  //     width: '3px', // width of the outline
  //     grayscaleOnDebugAll: true, // apply grayscale filter to every element
  //     // customClass: 'exampleClass',  // a class existent in your stylesheet
  //   }
  //   const debug = new Debucsser(config).init()
  // },

  copyright () {
    const style = 'color: #fff; background: #ffc308; padding: 4px 8px; border-left: 5px solid #282828; border-right: 5px solid #282828'
    debug('%cA creation by Boite à Oeufs', style)
    debug('%c> Site : https://www.boite-a-oeufs.com', style)
    debug('%c> Twitter : https://twitter.com/BoiteOeufs', style)
    debug('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)

    debug('%c> Design by Julian Camaret', style)
    debug('%c> Twitter : https://twitter.com/Jus_motion', style)

    debug('%cDevelopment by Romain Murschel', style)
    debug('%c> Site : https://romainmurschel.fr', style)
    debug('%c> Twitter : https://twitter.com/balthorg', style)
  }
}

// Kick shit off
App.init()
